import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import 'bootstrap'
import $ from "jquery"
import 'slick-carousel'
import AOS from 'aos'

window.$ = $;

window.toastr = require('toastr');

$(document).ready(function(){
    AOS.init();
});

function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      $('#navbar').addClass('scrolled');
    } else {
        $('#navbar').removeClass('scrolled');
    }
}
window.onscroll = function() {scrollFunction()};



